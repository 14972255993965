import ApiService from './ApiService'

export async function apiDiscordSignIn(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/oauth/discord/callback`,
        method: 'post',
        data,
    })
}

export async function apiGoogleSignIn(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/oauth/google`,
        method: 'post',
        data,
    })
}

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/users/login`,
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/users/register`,
        method: 'post',
        data,
    })
}

export async function apiGetUser(authToken) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/users/me`,
        method: 'get',
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
        data: {}
    })
}

export async function apiCreateAiGame(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/ai/game`,
        method: 'post',
        data,
    })
}
export async function apiGetUserActivityMap() {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/online-users/map`,
        method: 'get',
        
    })
}

export async function apiGetAiGamesForUser(data) {

    let limit = 10;
    if (data.limit) {
        limit = data.limit;
    }

    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/ai/games?limit=${limit}`,
        method: 'get',
        data,
    })
}
export async function apiUseReferralCode(code) {

    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/use_referral/${code}`,
        method: 'post',
    })
}

export async function apiDeleteAiGame(id) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/ai/game/${id}`,
        method: 'delete',
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}

export async function apiTestGameAPI() {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}`,
        method: 'get'
    })
}

export async function apiTestGameAssetAPI() {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/test/api/asset`,
        method: 'get'
    })
}

export async function apiTestRembgApi() {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/test/api/rembg`,
        method: 'get'
    })
}

export async function apiGetFriends(user_name, get_humans, selected_friend) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/friends/${user_name}/${get_humans}/${selected_friend}`,
        method: 'get'
    })
}

export async function apiGetChatHistory(user1, user2) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/chat?user1=${user1}&user2=${user2}`,
        method: 'get'
    })
}

export async function apiSendAiChatMessage(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/chat/ai`,
        method: 'post',
        data,
    })
}

export async function apiSendChatMessage(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/chat`,
        method: 'post',
        data,
    })
}
export async function apiCreateCommunity(data) {
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }
    const headers = {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    };
  
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/admin/create-community?name=${data.name}&description=${data.description}&mascot_id=${data.mascot_id}`,
        method: 'post',
        headers: headers,
        data: formData,
    });
}
export async function apiCreateAchievement(data) {
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }
    const headers = {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    };
  
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/admin/create-achievement?name=${data.name}&description=${data.description}&community_id=${data.community_id}&reward=${data.reward}&reward_name=${data.reward_name}&reward_text=${data.reward_text}&reward_type=${data.reward_type}&action=${data.action}&rank=${data.rank}&goal=${data.goal}&tag=${data.tag}&targets=${data.targets}`,
        method: 'post',
        headers: headers,
        data: formData,
    });
}

export async function apiLeaveRpgLobbyRequest(rpgUsername, rpgCode) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/game_lobby/leave/${rpgUsername}?code=${rpgCode}`,
        method: 'delete',
    })
}

export async function apiMarkChatAsRead(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/chat/read`,
        method: 'post',
        data,
    })
}

export async function apiIncrementGameView(game_id) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/stats/${game_id}/increment/views`,
        method: 'post'
    })
}

export async function apiGetGames() {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/games`,
        method: 'get'
    })
}
export async function apiGetTopEventUsers(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/events/top_users?count=`+data.count+"&timeframe="+data.timeframe+"&event="+data.event,
        method: 'get',
    })
}


export async function apiStatsGetTopRpgUsers() {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/top/rpg`,
        method: 'get'
    })
}

export async function apiStatsGetTopChatSent() {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/top/sent`,
        method: 'get'
    })
}

export async function apiStatsGetTopChatReceived() {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/top/received`,
        method: 'get'
    })
}

export async function apiStatsGetTopPages() {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/stats/top/pages`,
        method: 'get'
    })
}
export async function apiGetExperienceData(data) {
    let base_url = `${process.env.REACT_APP_API_URL}/experience-data`
    if (data?.category){
        base_url += `?category=${data?.category}`
        if (data?.subCategory){
            base_url += `&sub_category=${data?.subCategory}`
        }
    }

    return ApiService.fetchData({
        url: base_url,
        method: 'get'
    })
}
export async function apiGenerateImage(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/dreambox/ai/character/image`,
        method: 'post',
        data,
    })
}

export async function apiAiNewCharacter(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/ai/new/character`,
        method: 'post',
        data,
    })
}

export async function apiGetAiCharacters(username) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/ai/characters?username=${username}`,
        method: 'get'
    })
}

export async function apiGetAiCharacter(permalink_or_id) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/ai/details/${permalink_or_id}`,
        method: 'get'
    })
}

export async function apiGroupChat(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/chat/group/ai`,
        method: 'post',
        data,
    })
}
export async function apiSendDreampetGroupChat(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/dream_pets/chat/group`,
        method: 'post',
        data,
    })
}

export async function apiSendDreampetBattleChat(data) {
    
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/dream_pets_battle/chat/group`,
        method: 'post',
        data,
    })
}
export async function apiSendDreampetBossBattleChat(data) {
    
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/dream_pets_boss_battle/chat/group`,
        method: 'post',
        data,
    })
}
export async function apiVoteWordGameTheme(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/word_game/rooms/${data.room_id}/theme/${data.room_theme}?force=${data.force}`,
        method: 'post',
        
    })
}
export async function apiVoteWordGameSkip(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/word_game/rooms/${data.room_id}/skip?force=${data.force}`,
        method: 'post',
        
    })
}
export async function apiSendReactionToBackend(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/chat/reaction/${data.commentId}?uid=${data.uid}&reaction=${data.emoji}&collection=${data.collection}`,
        method: 'post',
        
    })
}
export async function apiSendEventReaction(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/events/reaction/${data.reaction}?event=${data.event}&uid=${data.uid}`,
        method: 'post',
        
    })
}
export async function apiSendLike(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/like/${data.documentId}?collection=${data.collection}`,
        method: 'post',
        
    })
}
export async function apiGetLike(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/like/${data.documentId}?collection=${data.collection}`,
        method: 'get',
        
    })
}
export async function apiGetEventReactions(event) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/events/reaction/${event}`,
        method: 'get',
        
    })
}
export async function apiGetEventData(event) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/events/data/${event}`,
        method: 'get',
        
    })
}
export async function apiGetCommunityData() {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/communities`,
        method: 'get',
        
    })
}
export async function apiGetSingleCommunityData(community_id) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/communities/single/${community_id}`,
        method: 'get',
        
    })
}
export async function apiGetTopCommunityData() {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/communities/top`,
        method: 'get',
        
    })
}
export async function apiGetMyCommunityData(uid) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/communities/user/${uid}`,
        method: 'get',
        
    })
}
export async function apiGetProfile(uid) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/users/profile/${uid}`,
        method: 'get',
        
    })
}
export async function apiLeaveCommunity(community_name) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/communities/leave/${community_name}`,
        method: 'post'
    })
}
export async function apiJoinCommunity(community_name) {
    console.log(community_name)
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/communities/join/${community_name}`,
        method: 'post'
    })
}

export async function apiSaveAvatar(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/users/profile`,
        method: 'post',
        data,
    })
}

export async function apiGetFeed(start_after) {
    if (start_after) {
        return ApiService.fetchData({
            url: `${process.env.REACT_APP_API_URL}/feed?start_after=${start_after}`,
            method: 'get',
        })
    } else {
        return ApiService.fetchData({
            url: `${process.env.REACT_APP_API_URL}/feed`,
            method: 'get',
        })
    }
}
export async function apiGetAchievements() {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/achievements`,
        method: 'get'
    })
}

export async function apiGetAiVsAiChatList(user_id) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/ai/vs/ai/list?user_id=${user_id}`,
        method: 'get'
    })
}

export async function apiGetAiVsAiChatHistory(ai_vs_ai_chat_id) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/ai/vs/ai/history?ai_vs_ai_chat_id=${ai_vs_ai_chat_id}`,
        method: 'get'
    })
}

export async function apiSendAiVsAiMessage(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/ai/vs/ai/prompt`,
        method: 'post',
        data,
    })
}

export async function apiCreateAiVsAiChat(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/ai/vs/ai/chat`,
        method: 'post',
        data,
    })
}


// Utility function to make all sorts of calls to the API
export async function apiRequest(path, method = 'get', data = {}, timeout = 1 * 60 * 1000) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}${path}`,
        method: method,
        data,
        timeout: timeout,
    })
}


import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    authority: [],
    id: null,
    created_at: null,
    name: ''
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
        setAvatar: (state, action) => {
            state.avatar = action.payload
        },
        updateUsername: (state, action) => {
            state.userName = action.payload
            state.name = action.payload
        }
    },
})

export const { setUser, setAvatar, updateUsername } = userSlice.actions

export default userSlice.reducer
